<template>
  <div class="carrossel_container">
    <Flicking
      class="carrossel_box"
      :options="options"
      :plugins="plugins"
      ref="flicking"
    >
      <div
        v-for="(card, index) in conteudoBlog"
        :key="index"
        class="slide-card"
      >
        <div class="card">
          <a
            :href="card.link"
            aria-label="Linkedin"
            target="_blank"
            class="titulo"
          >
            <img :src="card.imagem" alt="" class="img" />

            {{ card.titulo }}
          </a>

          <div class="descricao">
            {{ card.descricao }}
          </div>
        </div>
      </div>
      <div slot="viewport" class="flicking-pagination"></div>
    </Flicking>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";

import listaBlog from "@/helpers/lista_blog_tela_inicial.json";

export default {
  components: {
    Flicking: Flicking,
  },
  data() {
    return {
      options: {
        circular: true,
        align: "center",
        changeOnHold: true,
        circular: true,
        defaultIndex: 0,
      },
      plugins: [new Pagination({ type: "bullet" })],
    };
  },
  methods: {},
  computed: {
    conteudoBlog() {
      return listaBlog;
    },
  },
};
</script>

<style lang="scss" scoped>
.carrossel_container {
  width: 100%;
  height: 430px;
  padding: 0px 0 0 0;
  overflow: hidden;
  display: flex;
}
.carrossel_box {
  width: 100%;
  margin: 0 0px 0 -30px;
  position: absolute;
}

.slide-card {
  height: 430px;
  max-width: 345px;
  cursor: pointer;

  @include mediaQueryMax(large) {
    max-width: 290px;
  }
}

.card {
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 30px 30px 30px rgba(23, 55, 127, 0.3);
  display: flex;
  flex-direction: column;
  padding: 20px;
  place-items: start;
  transition: 0.5s;
  height: 320px;
  width: 220px;
  margin: 20px 50px;
  border: none;

  &:hover {
    transform: scale(1.1);
  }
  .img {
    border-radius: 17px;
    margin-bottom: 5px;
  }
  .titulo {
    color: #505050;

    font-size: 13px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 5px;
    text-align: left;

    &:hover {
      color: #063f6e;
    }
  }

  .descricao {
    color: #505050;

    width: 100%;
    font-size: 10px;
    text-align: left;
    line-height: 15px;
  }
}
</style>
<style lang="scss">
.flicking-pagination {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.flicking-pagination .flicking-pagination-bullet-active {
  background-color: #063f6ea6 !important;
}

.flicking-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  display: inline-block;
  margin: 0 4px;
  border-radius: 50%;
  background-color: rgb(10 10 10 / 10%);
  cursor: pointer;
  font-size: 1rem;
}
</style>
