<template>
  <div class="container_dash">
    <div class="banner_container">
      <img class="banner_img" src="img/tela-inicial/Banner1.png" alt="" />
    </div>
    <div class="history_container">
      <div class="titulo_container">
        <span id="icone_historico" class="icone_box">
          <img
            class="hist_logo"
            style="opacity: 0.8"
            src="img/tela-inicial/Historico.png"
            alt=""
          />
        </span>
        <b-tooltip
          target="icone_historico"
          triggers="hover"
          placement="topright"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          Histórico dos últimos 30 dias
        </b-tooltip>
        <p class="titulo_text">Últimas consultas</p>
        <div class="button-box">
          <div class="button_container">
            <img src="img/tela-inicial/Lupa.png" alt="" class="button_icone" />
            <button class="button_nova" @click="abreConsultas = !abreConsultas">
              Nova Consulta
            </button>
          </div>
          <div v-if="abreConsultas" class="button-add">
            <div class="button_container add">
              <button class="button_sub" @click="goConsulta">
                Consultar Box
              </button>
            </div>
            <div class="button_container add">
              <button class="button_sub" @click="goSuperbox">
                Consultar Superbox
              </button>
            </div>
            <div class="button_container add">
              <button class="button_sub" @click="goModelos">
                Consultar Modelos
              </button>
            </div>
          </div>
        </div>
        <div class="button_container">
          <img
            src="img/tela-inicial/Historio-button.png"
            alt=""
            class="button_icone"
          />
          <button class="button_nova hist" @click="goHistorico">
            Histórico
          </button>
        </div>
      </div>
    </div>
    <div class="itens_container">
      <ItensHistorico
        v-for="(consulta, index) in resultados"
        :key="index"
        :consulta="consulta"
        :index="index"
        :resultados="resultados"
      />
      <div
        class="itens_vazios"
        v-for="indexEmpty in qtdVazios"
        :key="`empty-${indexEmpty}`"
      >
        <div class="hist_box_emp">
          <span class="hist_empty">Sem <br />histórico</span>
          <span class="material-icons hist_icon"> block </span>
          <span class="cod_empty">-----</span>

          <span class="hist_botao">Acessar</span>
        </div>
      </div>
    </div>
    <div class="history_container">
      <div class="titulo_container blog">
        <span id="icone_historico" class="icone_box" style="margin: 0">
          <img
            class="hist_logo"
            style="opacity: 0.5"
            src="img/tela-inicial/blog.png"
            alt=""
          />
        </span>
        <p class="titulo_text">Blog</p>
      </div>
    </div>
    <CarrosselBlog />
  </div>
</template>

<script>
import { historicoService } from "@/services";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import CarrosselBlog from "@/pages/Dashboard/CarrosselBlog.vue";
import ItensHistorico from "@/pages/Dashboard/ItensHistorico.vue";

export default {
  data() {
    return {
      resultados: [],
      periodoDe: "",
      periodoAte: "",
      iconeTipoPessoa: "",
      qtdVazios: 5,
      abreConsultas: false,
      msgErro: "",
      erro: false,
    };
  },
  components: {
    CarrosselBlog,
    ItensHistorico,
  },

  methods: {
    async recuperaHistorico() {
      const objetoHistorico = {
        periodoDeBrz: this.$moment().subtract(30, "days").format("YYYY-MM-DD"),
        periodoAteBrz: this.$moment().format("YYYY-MM-DD"),
        quantidadeRegistros: 5,
        pagina: 1,
        ordenacao: "dataDesc",
      };

      const query = new URLSearchParams(objetoHistorico);
      this.resultados = await historicoService.recuperaDadosHistorico(
        query.toString()
      );
      this.qtdVazios = this.qtdVazios - this.resultados.length;

      this.verificaTipoConsulta();
    },

    verificaTipoConsulta() {
      for (var resultado of this.resultados) {
        let contaSUP = 0;
        let contaNSUP = 0;

        for (const consulta of resultado.Consultas) {
          if (consulta.Parametros.cod_ref.startsWith("BOXSUPPF")) {
            contaSUP++;
          }
          if (!consulta.Parametros.cod_ref.startsWith("BOXSUPPF")) {
            contaNSUP++;
          }
        }

        if (contaSUP >= 6) {
          resultado.Tipo = "superbox";
        } else if (
          contaSUP === 0 &&
          contaNSUP >= 1 &&
          resultado.Rota == "v3/consulta"
        ) {
          resultado.Tipo = "box";
        } else if (
          (contaSUP <= 2 && contaNSUP == 1) ||
          (resultado.Rota == "v3/modelo" && contaSUP <= 2)
        ) {
          resultado.Tipo = "modelo";
        }
      }
    },

    goConsulta() {
      this.$router.push("/consultas/box/0");
    },
    goSuperbox() {
      this.$router.push("/consultas/superbox/0");
    },
    goModelos() {
      this.$router.push("/consultas/modelos/0");
    },
    goHistorico() {
      this.$router.push("/consultas/historico");
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  mounted() {
    this.recuperaHistorico();

    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };
    this.recebeContexto(contexto);
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.container_dash {
  padding: 0 1rem;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  align-items: center;
}
.heading_dash {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  img {
    width: 200px;
  }
}

.banner_container {
  width: 100%;
  margin: 3rem 0 3rem 0;
  display: flex;
  justify-content: center;
  .banner_img {
    width: 980px;
  }
}

.itens_container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .itens_vazios {
    margin: 10px 0 10px 0;
  }
}
.history_container {
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 980px;
  justify-content: space-between;
  flex-wrap: wrap;
  .titulo_container {
    display: flex;
    width: 100%;
    margin: 0 0 20px 0;
    align-items: center;
    border-bottom: 2px dashed #b1b1b1;
    &.blog {
      height: 70px;
    }
    .titulo_text {
      width: 80%;
      color: #9c9c9c;
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      cursor: default;
    }
  }
  .icone_box {
    display: flex;
    width: 70px;
    margin-right: 20px;
    cursor: default;
  }
  .hist_logo {
    width: 40px;
    height: 40px;
  }
}

.button-add {
  position: absolute;
  z-index: 20;
}

.button_container {
  height: 60px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  transition: 0.5s;
  cursor: pointer;

  &.add {
    height: 45px;
    margin: 0 0 0 30px;
    background-color: transparent;
  }

  .button_icone {
    width: 40px;
    position: absolute;
  }
  .button_nova {
    width: 170px;
    height: 40px;
    font-size: 14px;
    padding: 1px 5px;
    color: white;
    border: none;
    background-image: url(/img/tela-inicial/Botao-Nova.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-transform: uppercase;
    font-weight: 600;
    text-align: right;
    padding: 0 15px 0 0;

    &.hist {
      padding: 0 40px 0 0;
    }
  }
  &:hover {
    transform: scale(1.1);
  }

  .button_sub {
    width: 170px;
    height: 40px;
    font-size: 12px;
    padding: 1px 5px;
    color: white;
    border: none;
    background-image: url(/img/tela-inicial/Botao-Nova.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
  }
}

.hist_box_emp {
  width: 145px;
  height: 155px;
  background-image: url(/img/tela-inicial/Caixa-Acessar-cinza-maior.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0 10px 0 10px;
  cursor: not-allowed;

  .hist_empty {
    width: 80%;
    font-size: 14px;
    padding: 0 0 9px 0;
    border-bottom: 1px solid #9c9c9c;
    color: #8d8d8d;
    text-align: center;
    margin: 2px 15px 0 15px;
    line-height: 21px;
  }
  .cod_empty {
    font-size: 14px;
    padding: 0 0 10px 0;
    color: #8d8d8d;
    text-align: center;
  }
  .hist_icon {
    margin-top: 5px;
    font-size: 28px;
    color: #9c9c9c;
    line-height: 28px;
  }
  .hist_botao {
    text-transform: uppercase;
    color: white;
    font-size: 13px;
    font-weight: 400;
    padding: 0px 3px 0 0;
    line-height: 18px;
  }
}

@media (max-width: 600px) {
  .blog {
    height: 90px !important;
  }
  .container_dash {
    gap: 20px;
  }
  .titulo_container {
    flex-direction: column;
  }

  .titulo_text {
    width: fit-content !important;
  }

  .icone_box {
    margin: 0 !important;
    width: fit-content !important;
  }

  .button_container {
    margin: 0 !important;
  }

  .itens_container {
    overflow: hidden;
    overflow-x: scroll;
  }
}
</style>
