<template>
  <div class="hist_box">
    <router-link
      class="codigo"
      :to="`consultas/historico/${consulta.CodigoAgrupador}`"
    >
      <span class="hist_data">
        {{ toDataHoraPtbr(consulta.DataTransacaoBrz) }}
      </span>
      <span class="icones-box" :class="{ trilha: origemDaTrilha }">
        <span class="material-icons hist_icon" :id="'iconTarget' + index">
          {{ iconeTipoConsulta }}
        </span>
        <span
          v-if="origemDaTrilha"
          class="material-symbols-outlined hist_icon trilha"
          :id="'iconTrilha' + index"
        >
          recent_actors
        </span>
        <b-tooltip
          v-if="origemDaTrilha"
          :target="'iconTrilha' + index"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          Trilha de origem: <br />{{ textoOrigemDaTrilha }}
        </b-tooltip>
      </span>
      <span class="hist_cod">
        {{ consulta.CodigoAgrupador }}
      </span>
      <b-tooltip
        :target="'iconTarget' + index"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Consultas realizadas:
        <br />
        <span
          class="iconTooltip"
          v-for="produto in produtosBox"
          :key="produto.Nome"
        >
          <span class="nome">
            <span class="material-icons icon" v-if="produto.Nome != 'Superbox'">
              {{ produto.Icone }}
            </span>
            <img
              :src="produto.Icone"
              class="img-superbox"
              v-if="produto.Nome == 'Superbox'"
            />
            {{ produto.Nome }}
          </span>
          <span
            class="lista-param"
            v-for="(param, index) in produto.Doc"
            :key="index"
          >
            {{ formataDocumento(param) }}
          </span>
        </span>
      </b-tooltip>

      <span class="hist_botao">Acessar</span>
    </router-link>
  </div>
</template>

<script>
import { produtosStore } from "@/stores/produtos";
import { userPreferenceStore } from "@/stores/userPreference";

import { mapActions, mapWritableState } from "pinia";
import produtosJson from "@/helpers/produtosControle.json";
import moment from "moment";

export default {
  name: "ItensHistorico",

  props: {
    resultados: Array,
    consulta: Object,
    index: Number,
  },

  data() {
    return {
      listaRotasModelos: [],
      documentosAdicionados: [],
    };
  },
  components: {},

  methods: {
    formatarCPF(cpf) {
      if (cpf && cpf != "-") {
        cpf = cpf.replace(/\D/g, "");

        cpf = cpf.replace(/^(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
        cpf = cpf.replace(/\.(\d{3})(\d)/, ".$1-$2");

        if (this.ocultarDados) {
          return cpf.replace(
            /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
      return cpf;
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },

    recuperaListaModelos() {
      let objetos = new Set();
      for (let obj1 of this.resultados) {
        for (let modelo of this.modelos) {
          for (let box of obj1.Consultas) {
            if (box.Parametros.cod_ref === modelo.Valor) {
              objetos.add(modelo.Valor);
            }
          }
        }
      }

      this.listaRotasModelos = [...objetos];
    },

    toDataHoraPtbr(dateTime) {
      const date = new Date(dateTime);
      const formattedDate = `${this.formatTwoDigits(
        date.getDate()
      )}/${this.formatTwoDigits(
        date.getMonth() + 1
      )}/${date.getFullYear()} ${this.formatTwoDigits(
        date.getHours()
      )}:${this.formatTwoDigits(date.getMinutes())}:${this.formatTwoDigits(
        date.getSeconds()
      )}`;
      return formattedDate;
    },

    formatTwoDigits(value) {
      return value < 10 ? "0" + value : value;
    },

    buscarDocumentos(produto) {
      const documentos = [];

      for (let i = 0; i < this.consulta.Consultas.length; i++) {
        const consulta = this.consulta.Consultas[i];

        if (
          consulta.Parametros &&
          consulta.Parametros.cod_ref.includes(produto.Cod_ref)
        ) {
          const cpf = consulta.Parametros.cpf;
          const cnpj = consulta.Parametros.cnpj;

          if (cpf && !documentos.includes(cpf)) {
            this.removerDocumentoAnterior(cpf, produto);
            documentos.push(cpf);
            this.documentosAdicionados.push({ documento: cpf, produto }); // Mapeia documento para o produto
          }

          if (cnpj && !documentos.includes(cnpj)) {
            this.removerDocumentoAnterior(cnpj, produto);
            documentos.push(cnpj);
            this.documentosAdicionados.push({ documento: cnpj, produto }); // Mapeia documento para o produto
          }
        }
      }

      return documentos;
    },

    removerDocumentoAnterior(documento, produtoAtual) {
      this.documentosAdicionados.forEach((item, index) => {
        if (item.documento === documento) {
          const produtoAnterior = item.produto;
          const docIndex = produtoAnterior.Doc.indexOf(documento);

          if (docIndex !== -1) {
            produtoAnterior.Doc.splice(docIndex, 1);
          }

          this.documentosAdicionados.splice(index, 1);
        }
      });
    },

    formataDocumento(documento) {
      if (documento.length > 11) {
        return "CNPJ: " + this.formatarCNPJ(documento);
      } else {
        return "CPF: " + this.formatarCPF(documento);
      }
    },

    ...mapActions(produtosStore, ["getModelos"]),

    ...mapActions(userPreferenceStore, ["syncPreferenciaOcultarDados"]),
  },

  computed: {
    textoOrigemDaTrilha() {
      if (this.consulta.TextoMetadados.includes("origemTrilha")) {
        const termoOrigem = this.consulta.TextoMetadados.split(";").find(
          (termo) => termo.startsWith("origemTrilha=")
        );
        return termoOrigem ? termoOrigem.split("=")[1] : "";
      }
      return "";
    },
    origemDaTrilha() {
      if (this.consulta.TextoMetadados.includes("origemTrilha")) {
        return true;
      }
      return false;
    },

    iconeTipoConsulta() {
      const consultas = this.consulta.Consultas;
      if (this.consulta.Tipo != "superbox") {
        for (const i in consultas) {
          if (consultas[i] && consultas[i].Parametros) {
            if (
              consultas[i].Parametros.cnpj &&
              consultas[i].Parametros.cnpj != ""
            ) {
              return "business";
            } else if (
              consultas[i].Parametros.cpf &&
              consultas[i].Parametros.cpf != ""
            ) {
              return "face";
            } else if (
              consultas[i].Parametros.cep &&
              consultas[i].Parametros.cep != ""
            ) {
              return "location_on";
            }
            return "";
          }
        }
      } else {
        return "face";
      }

      return "";
    },

    todosProdutos() {
      return [...produtosJson.produtosObrigatorios, ...produtosJson.produtos];
    },

    produtosBox() {
      const produtosSuperbox = [
        "Dados Cadastrais",
        "Sonar Outbox",
        "Endereços e Geolocalidades",
        "Associações Pessoais",
        "Mapa Geral de Riscos",
        "Renda Presumida",
        "Conexões com Empresas",
        "Histórico de Passagens",
        "Alertas Gerais",
      ];
      const produtosKYC = [
        "KYC & Due Diligence",
        "KYC & Due Diligence2",
        "KYC & Due Diligence3",
        "KYC & Due Diligence4",
      ];

      var produtos = [];
      const consultas = this.consulta.Consultas;

      for (var i in this.consulta.Consultas) {
        const codRef = consultas[i].Parametros.cod_ref;

        let produtoEncontrado = this.todosProdutos.find(
          (produto) => produto.Box === codRef
        );

        if (produtoEncontrado) {
          if (produtoEncontrado.Nome.startsWith("Bloco 0")) {
            produtos.push({
              Nome: produtoEncontrado.Nome.split("- ")[1],
              Icone: produtoEncontrado.Icone,
              Cod_ref: codRef,
            });

            const bloco3Index = produtos.findIndex(
              (produto) => produto.Nome === "Associações Pessoais"
            );

            const bloco9Index = produtos.findIndex(
              (produto) => produto.Nome === "Alertas Gerais"
            );

            if (bloco9Index != -1) {
              produtos.splice(bloco9Index, 1);
            }
            if (bloco3Index != -1) {
              produtos.push({
                Nome: "Alertas Gerais",
                Icone: "report_problem",
                Cod_ref: codRef,
              });
            }
          } else {
            produtos.push({
              Nome: produtoEncontrado.Nome,
              Icone: produtoEncontrado.Icone,
              Cod_ref: codRef,
            });
          }
        } else {
          var novoNome = codRef;
          novoNome =
            codRef == "BOXPRPJ001" || codRef == "BOXPRPJ002"
              ? "Faturamento Presumido"
              : codRef;

          if (!produtos.some((produto) => produto.Nome === novoNome)) {
            produtos.push({
              Nome: novoNome,
              Icone: "speed",
              Cod_ref: codRef,
            });
          }
        }
      }

      const superboxPresente = produtosSuperbox.every((nome) =>
        produtos.some((produto) => produto.Nome == nome)
      );

      if (superboxPresente) {
        produtos = produtos.filter(
          (obj) => !produtosSuperbox.includes(obj.Nome)
        );
        produtos.push({
          Nome: "Superbox",
          Icone: "/img/superbox/logo-superbox.png",
          Cod_ref: "BOXSUPPF00",
        });
      }

      const indexSuperbox = produtos.findIndex((p) => p.Nome === "Superbox");

      if (indexSuperbox > -1) {
        const [produtoEncontrado] = produtos.splice(indexSuperbox, 1);

        produtos.unshift(produtoEncontrado);
      }

      const KYCPresente = produtosKYC.every((nome) =>
        produtos.some((produto) => produto.Nome == nome)
      );

      if (KYCPresente) {
        produtos = produtos.filter((obj) => !produtosKYC.includes(obj.Nome));
        produtos.unshift({
          Nome: "KYC & Due Diligence",
          Icone: "fact_check",
          Cod_ref: "BOXKYC00",
        });
      }

      const indexKYC = produtos.findIndex((p) =>
        p.Nome.includes("KYC & Due Diligence")
      );

      if (indexKYC > -1) {
        const [produtoEncontrado] = produtos.splice(indexKYC, 1);

        produtos.push(produtoEncontrado);
      }

      produtos.forEach((produto) => {
        var docs = this.buscarDocumentos(produto);
        produto.Doc = docs;
      });

      return produtos;
    },

    ...mapWritableState(produtosStore, {
      modelos: "modelos",
    }),

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
    }),
  },

  async mounted() {
    await this.getModelos();
    await this.recuperaListaModelos();

    this.ocultarDados = await this.syncPreferenciaOcultarDados();
  },
};
</script>

<style lang="scss" scoped>
.hist_box {
  margin: 10px 0 10px 0;
}
.codigo {
  width: 145px;
  height: 155px;
  background-image: url(/img/tela-inicial/Caixa-Acessar-maior.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0 10px 0 10px;
  // line-height: 24px;

  cursor: pointer;
  transition: transform 0.5s ease;

  .hist_data {
    font-size: 14px;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #9c9c9c;
    color: #8d8d8d;
    text-align: center;
    margin: 7px 15px 0 15px;
    line-height: 21px;
  }

  .icones-box {
    display: flex;

    &.trilha {
      position: relative;
      left: 10px;
    }
  }

  .hist_icon {
    margin: 5px 0 0 0;
    font-size: 28px;
    line-height: 28px;

    &.trilha {
      font-size: 24px;
      width: 25px;
      position: relative;
      left: 10px;
      cursor: default;
      opacity: 0.6;
      z-index: 10;
    }
  }

  .hist_cod {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
  }

  .hist_botao {
    text-transform: uppercase;
    color: white;
    font-size: 13px;
    font-weight: 400;
    padding: 13px 3px 0 0;
    line-height: 18px;
  }

  &:hover {
    transform: scale(1.1);

    .hist_cod {
      color: #044e8a;
    }
    .hist_icon {
      color: #044e8a;

      &.trilha {
        color: #0092f6;
      }
    }
    .hist_botao {
      color: #044e8a;
    }
  }
}

.lista-param {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 5px;
}

.iconTooltip {
  color: white;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin: 5px 5px;
  line-height: 18px;
}

.nome {
  display: flex;
  align-items: center;
}

.docTooltip {
  color: white;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 3px;
  flex-direction: column;
  gap: 3px;
  line-height: 18px;
}

.icon {
  height: 20px;
  width: 20px;
  margin: 0px 10px 6px 0;
  line-height: 18px;
}
.img-superbox {
  height: 25px;
  width: 25px;
  margin: 0px 10px 0px 0;
}
</style>
