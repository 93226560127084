<template>
  <footer class="footer">
    <div class="container md-offset">
      <nav>
        <ul class="footerList">
          <li>
            <a
              href="https://www.outbox.com.br/sobre"
              target="_blank"
              class="footerText"
              >Sobre nós</a
            >
          </li>
          <li>
            <a
              href="https://api.outbox.com.br"
              target="_blank"
              class="footerText"
              >Documentação da API</a
            >
          </li>
          <li>
            <a href="/suporte/contato" class="footerText">Contato</a>
          </li>
          <li>
            <a href="/suporte/referencia" class="footerText"
              >Política de Privacidade</a
            >
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://www.outbox.com.br" target="_blank" class="footerText"
          >Outbox & StepWise</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.container {
  @include mediaQueryMax(small) {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.footer {
  background-color: white;
}

.footer .footerList {
  @include mediaQueryMax(small) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.copyright {
  display: flex;
  position: relative;
  z-index: 10;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.footerText {
  font-size: 14px;
}
</style>
