<template>
  <div class="user">
    <div class="photo">
      <img :src="base64Foto" alt="avatar" />
    </div>
    <div class="user-info">
      <a
      data-toggle="collapse"
      :aria-expanded="!isClosed"
      @click.stop="toggleMenu"
      @click.capture="clicked"
      >
      <span>
        <span style="display: block !important; overflow: hidden; text-overflow: ellipsis;width:10em">{{ nomeAmigavel }}</span>
        <b class="caret"></b>
      </span>
    </a>
    
    <collapse-transition>
      <div v-show="!isClosed">
        <ul class="nav">
          <slot>
            <li>
              <router-link class="sidebar-normal" to="/conta" @click.native="linkClick">
                <span class="sidebar-mini"><i class="fas fa-user"></i></span>
                <span class="sidebar-normal">Minha Conta</span>
              </router-link>
            </li>
            <li>
              <router-link class="sidebar-normal" to="/preferencias" @click.native="linkClick">
                <span class="sidebar-mini"><i class="fas fa-star"></i></span>
                <span class="sidebar-normal">Preferências</span>
              </router-link>
            </li>
            <li v-on:click="perguntaSair()">
              <a>
                <span class="sidebar-mini"><i class="fas fa-sign-out-alt"></i></span>
                <span class="sidebar-normal">Sair</span>
              </a>
            </li>
          </slot>
        </ul>
      </div>
    </collapse-transition>
  </div>
</div>
</template>
<script>

import { logout, constantes } from '@/helpers';
import { CollapseTransition } from "vue2-transitions";
import { userService } from '@/services';

export default {
  components: {
    CollapseTransition
  },
  props: {
  },
  data() {
    return {
      isClosed: true,
      base64Foto : "",
      nomeAmigavel: ""
    };
  },
  methods: {
    clicked: function(e) {
      e.preventDefault();
    },
    toggleMenu: function() {
      this.isClosed = !this.isClosed;
    },
    perguntaSair(){
      logout.perguntaSair();
    },
    atualizaFotoComPadraoSeNaoExistir(){
      if (!this.base64Foto){
        this.base64Foto = constantes.getImagemAvatarPadrao();
      }
    },
    atualizaNomeAmigavelComPadraoSeNaoExistir(){
      if (!this.nomeAmigavel){
        this.nomeAmigavel = constantes.getNomeUsuarioPadrao();
      }
    },
    linkClick() {
      this.$sidebar.displaySidebar(false);
    }
  },
  async mounted () {
    
    this.atualizaFotoComPadraoSeNaoExistir();
    
    this.$root.$on("atualizouDadosUsuario", dados => {
      this.nomeAmigavel = dados.nomeAmigavel;
      this.base64Foto = dados.base64Foto;
      this.atualizaFotoComPadraoSeNaoExistir();
      this.atualizaNomeAmigavelComPadraoSeNaoExistir();
    });
    
    userService.recuperaDadosUsuarioLogado()
    .then(
    usuario => {
      this.nomeAmigavel = usuario.NomeAmigavel;
      this.base64Foto = usuario.Foto;
      this.atualizaFotoComPadraoSeNaoExistir();
      this.atualizaNomeAmigavelComPadraoSeNaoExistir();
    },
    erro => {
      this.nomeAmigavel = "";
      this.base64Foto = "";
      this.atualizaFotoComPadraoSeNaoExistir();
      this.atualizaNomeAmigavelComPadraoSeNaoExistir();
    }
    );
  }
};
</script>
<style scoped>
.collapsed {
  transition: opacity 1s;
}

.sidebar-normal {
  text-align: right;
  display: flex !important;
}

.nav {
  position: relative;
  margin-left: 20px !important;
}
</style>
