var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-total",class:[{ 'sem-menu': _vm.desabilitaMenuLateral }]},[_c('div',{staticClass:"wrapper",class:[
      {
        'nav-open': _vm.$sidebar.showSidebar,
        'sem-menu': _vm.desabilitaMenuLateral,
      },
    ]},[_c('notifications'),(!_vm.desabilitaMenuLateral)?_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Inicio', icon: 'dashboard', path: '/inicio' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Consultas', icon: 'apps' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Box', path: '/consultas/box/0', icon: 'apps' }}}),(_vm.permissoes.paginaSuperbox)?_c('sidebar-item',{attrs:{"link":{ name: 'Superbox', path: '/consultas/superbox/0' }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
              name: 'Modelos',
              path: '/consultas/modelos/0',
              icon: 'speed',
            }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Histórico', path: '/consultas/historico' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Backtest', icon: 'post_add' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Nova', path: '/backtest/nova/0' }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Histórico',
              path: '/backtest/historico_backtest',
            }}})],1),(_vm.permissoes.paginaGestorBases)?_c('sidebar-item',{attrs:{"link":{
            name: 'Gestor de Bases',
            icon: 'drive_folder_upload',
          }}},[(_vm.permissoes.paginaGestorBases)?_c('sidebar-item',{attrs:{"link":{
              name: 'Meus projetos',
              path: '/gestor_bases/projetos',
              icon: 'drive_folder_upload',
            }}}):_vm._e(),(_vm.permissoes.paginaGestorBases)?_c('sidebar-item',{attrs:{"link":{
              name: 'Área de transferência',
              path: '/gestor_bases/area_transferencia',
              icon: 'drive_folder_upload',
            }}}):_vm._e(),(_vm.permissoes.paginaGestorBases)?_c('sidebar-item',{attrs:{"link":{
              name: 'Histórico',
              path: '/gestor_bases/historico',
              icon: 'drive_folder_upload',
            }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: 'Suporte', icon: 'build' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Referência', path: '/suporte/referencia' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Console da API', path: '/suporte/console_api' }}})],1),_c('sidebar-item',{attrs:{"link":{
            name: 'Documentação',
            icon: 'collections_bookmark',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Books',
              path: '/doc/books',
              icon: 'collections_bookmark',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Box de Modelos',
              path: '/doc/box_modelos',
              icon: 'collections_bookmark',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Box de Atributos',
              path: '/doc/box_atributos',
              icon: 'collections_bookmark',
            }}})],1),(_vm.permissoes.usuarioBeta)?_c('sidebar-item',{attrs:{"link":{
            name: 'Singú',
            path: '/singu/chat',
            icon: 'forum',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Contato',
            icon: 'alternate_email',
            path: '/suporte/contato',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Atualizações',
            path: '/logs',
            icon: 'notification_important',
          }}})],1)],2):_vm._e(),_c('div',{staticClass:"main-panel",attrs:{"id":"painel_principal"}},[_c('img',{staticClass:"imgFundo",attrs:{"src":"/img/logo-outbox-fundo.png"}}),_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent }},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view',{on:{"modal-on-off":_vm.alteraComportamentoScroll}})],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1),(_vm.permissoes.iconeChatOutbox)?_c('ChatOutbox'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }