<template>
  <div class="wrapper-total" :class="[{ 'sem-menu': desabilitaMenuLateral }]">
    <div
      class="wrapper"
      :class="[
        {
          'nav-open': $sidebar.showSidebar,
          'sem-menu': desabilitaMenuLateral,
        },
      ]"
    >
      <notifications></notifications>
      <side-bar
        v-if="!desabilitaMenuLateral"
        :active-color="sidebarBackground"
        :background-image="sidebarBackgroundImage"
        :data-background-color="sidebarBackgroundColor"
      >
        <user-menu></user-menu>
        <mobile-menu></mobile-menu>
        <template slot="links">
          <sidebar-item
            :link="{ name: 'Inicio', icon: 'dashboard', path: '/inicio' }"
          ></sidebar-item>
          <sidebar-item :link="{ name: 'Consultas', icon: 'apps' }">
            <sidebar-item
              :link="{ name: 'Box', path: '/consultas/box/0', icon: 'apps' }"
            ></sidebar-item>
            <sidebar-item
              v-if="permissoes.paginaSuperbox"
              :link="{ name: 'Superbox', path: '/consultas/superbox/0' }"
            ></sidebar-item>

            <sidebar-item
              :link="{
                name: 'Modelos',
                path: '/consultas/modelos/0',
                icon: 'speed',
              }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Histórico', path: '/consultas/historico' }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item :link="{ name: 'Backtest', icon: 'post_add' }">
            <sidebar-item
              :link="{ name: 'Nova', path: '/backtest/nova/0' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Histórico',
                path: '/backtest/historico_backtest',
              }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item
            v-if="permissoes.paginaGestorBases"
            :link="{
              name: 'Gestor de Bases',
              icon: 'drive_folder_upload',
            }"
          >
            <sidebar-item
              v-if="permissoes.paginaGestorBases"
              :link="{
                name: 'Meus projetos',
                path: '/gestor_bases/projetos',
                icon: 'drive_folder_upload',
              }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="permissoes.paginaGestorBases"
              :link="{
                name: 'Área de transferência',
                path: '/gestor_bases/area_transferencia',
                icon: 'drive_folder_upload',
              }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="permissoes.paginaGestorBases"
              :link="{
                name: 'Histórico',
                path: '/gestor_bases/historico',
                icon: 'drive_folder_upload',
              }"
            >
            </sidebar-item>
          </sidebar-item>
          <sidebar-item :link="{ name: 'Suporte', icon: 'build' }">
            <sidebar-item
              :link="{ name: 'Referência', path: '/suporte/referencia' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Console da API', path: '/suporte/console_api' }"
            ></sidebar-item>
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Documentação',
              icon: 'collections_bookmark',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Books',
                path: '/doc/books',
                icon: 'collections_bookmark',
              }"
            >
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Box de Modelos',
                path: '/doc/box_modelos',
                icon: 'collections_bookmark',
              }"
            >
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Box de Atributos',
                path: '/doc/box_atributos',
                icon: 'collections_bookmark',
              }"
            >
            </sidebar-item>
          </sidebar-item>
          <sidebar-item
            v-if="permissoes.usuarioBeta"
            :link="{
              name: 'Singú',
              path: '/singu/chat',
              icon: 'forum',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Contato',
              icon: 'alternate_email',
              path: '/suporte/contato',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Atualizações',
              path: '/logs',
              icon: 'notification_important',
            }"
          ></sidebar-item>
        </template>
      </side-bar>
      <div class="main-panel" id="painel_principal">
        <img src="/img/logo-outbox-fundo.png" class="imgFundo" />
        <top-navbar></top-navbar>
        <div :class="{ content: !$route.meta.hideContent }">
          <zoom-center-transition :duration="200" mode="out-in">
            <router-view
              @modal-on-off="alteraComportamentoScroll"
            ></router-view>
          </zoom-center-transition>
        </div>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
    <ChatOutbox v-if="permissoes.iconeChatOutbox" />
  </div>
</template>

<script>
/* eslint-disable no-new */
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./MobileMenu.vue";
import UserMenu from "./UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";

import { mapWritableState, mapActions } from "pinia";
import { userPreferenceStore } from "@/stores/userPreference";
import ChatOutbox from "@/pages/AssistenteSingu/ChatOutbox.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    ZoomCenterTransition,
    ChatOutbox,
  },
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "azure",
      sidebarBackgroundImage: "",
      sidebarMini: false,
      sidebarImg: false,
      msgErroUsuario: "",
      userObj: {},

      permissoes: {
        usuarioBeta: false,
        paginaSuperbox: false,
        iconeChatOutbox: false,
        paginaGestorBases: false,
      },

      desabilitaMenuLateral: false,
    };
  },
  methods: {
    async recuperaDadosUsuario() {
      await this.recuperaUsuarioLogado();
      this.atualizaPermissoes();
    },

    atualizaPermissoes() {
      if (
        this.usuarioLogado.Grupos.includes(
          "Usuários StepWise com acesso ao SuperBox"
        ) ||
        this.usuarioLogado.Grupos.includes(
          "Acesso de clientes ao Portal Outbox - SuperBox"
        )
      ) {
        this.permissoes.paginaSuperbox = true;
      }

      if (
        this.usuarioLogado.Grupos.includes(
          "Funcionalidades Beta - Portal Outbox"
        )
      ) {
        this.permissoes.usuarioBeta = true;
      }
      if (
        this.usuarioLogado.Grupos.includes("Acesso ao Portal Outbox - Chat") ||
        this.usuarioLogado.Empresa == "StepWise"
      ) {
        this.permissoes.iconeChatOutbox = true;
      }

      if (this.usuarioLogado.Grupos.includes("Gestores de Bases")) {
        this.permissoes.paginaGestorBases = true;
      }
    },

    alteraComportamentoScroll(e) {
      var wrapperTotal = document.getElementsByClassName("wrapper-total")[0];
      var wrapper = document.getElementsByClassName("wrapper")[0];

      var body = document.body;

      if (e) {
        body.classList.add("body-hide");
        wrapperTotal.classList.add("body-hide");
      } else {
        body.classList.remove("body-hide");
        wrapperTotal.classList.remove("body-hide");
      }
    },
    hideSidebar() {
      this.$sidebar.showSidebar = false;
    },

    ...mapActions(userPreferenceStore, ["recuperaUsuarioLogado"]),
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
    }),
  },

  mounted() {
    this.hideSidebar();

    this.recuperaDadosUsuario();

    // const params = new URLSearchParams(window.location.search);

    // if (params.has("desabilitamenu") && params.get("desabilitamenu") === "1") {
    //   this.desabilitaMenuLateral = true;
    // } else {
    //   this.desabilitaMenuLateral = false;
    // }

    // if (params.has("token")) {
    //   const token = params.get("token");

    //   const currentTime = new Date();
    //   const timeIn24Hours = new Date(
    //     currentTime.getTime() + 24 * 60 * 60 * 1000
    //   );

    //   const formattedTime = timeIn24Hours.toISOString();

    //   const tokenResponse = {
    //     DataBrzExpiracao: formattedTime,
    //     Token: token,
    //   };

    //   sessionStorage.setItem("token", JSON.stringify(tokenResponse));
    // }

    // const newUrl =
    //   window.location.protocol +
    //   "//" +
    //   window.location.host +
    //   window.location.pathname;
    // window.history.replaceState({}, document.title, newUrl);
  },
};
</script>
<style lang="scss">
.wrapper-total {
  overflow: hidden;

  &.sem-menu {
    background-color: #eeeeee;
  }
}

.wrapper {
  &.sem-menu {
    display: flex;
    justify-content: center;
    height: 100%;
  }
}

.main-panel {
  .imgFundo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 500px;
    opacity: 0.5;
  }
}

$scaleSize: 0.95;
.body-hide {
  overflow: hidden;
}
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
